<template>
  <div class="hmf-inside-banner-main-container">
    <div class="container h-100">
      <div class="row h-100 my-auto">
        <div class="col-12 my-auto">
          <div class="hmf-breadcrumb">Beranda / Data Aduan</div>
          <div class="hmf-page-title">
            <h1 class="h1-hmf-title">Data Pengaduan Nasabah</h1></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container hmf-main-content-area">
    <PageDataPengaduan
        :useheader=false
        :usebutton=true
    ></PageDataPengaduan>
  </div>
  <PageFooter></PageFooter>
</template>

<script>
import PageFooter from "@/components/part/PageFooter";
import PageDataPengaduan from "@/components/pages/part/PageDataPengaduan";
export default {
  name: "PageAduan",
  components: {PageDataPengaduan, PageFooter}
}
</script>

<style scoped>

</style>