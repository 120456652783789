<template>
  <div class="footer-area">
    <div class="container">
      <div class="row h-100">
        <div class="col-md-4 "><p class="p-footer hmf-putih">
          <strong>PT HASJRAT MULTIFINANCE</strong></p>
          <p class="p-footer hmf-putih">
            Jl. RP. Soeroso No.38, Gondangdia, Menteng, Kota Jakarta Pusat, DKI Jakarta 10350
          </p>
          <p class="p-footer hmf-putih"><i class="fas fa-phone"></i> +62 21 3905913<br>
            <i class="far fa-envelope"></i> support@hmf.co.id<br>
            <i class="fab fa-facebook-f"></i> Hasjrat Multifinance</p>
        </div>
        <div class="col-md-4 ">
        </div>
        <div class="col-md-4"><p class="p-footer hmf-putih">Copyright &copy; 2020 - hmf.co.id<br> Site developed by
          EvolutionTeams.com </p></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style scoped>

</style>