<template>
  <Dialog v-model:visible="showmodal" :dismissableMask="true" @update:visible="toggleview" header="Export Data Pengaduan Nasabah" :style="{ width: '75vw' }" modal :contentStyle="{ height: 'auto' }">
    <div class="modal-body">
      <div class="header-aduan-modal" style="padding-left: 15px;">
        <div class="row">
          <div class="col-md-4">
            <strong>Tanggal aduan :</strong>
          </div>
          <div class="col-md-4">
            <strong><input type="date" v-model="dataForm.startdate"></strong> s/d <strong><input type="date" v-model="dataForm.enddate"></strong>
          </div>
          <div class="col-md-4">
            <ProgressSpinner v-if="loading" />
            <button v-else @click="generateReport" class="btn btn-success btn-md" ><i class="pi pi-file-export "></i> Generate</button>
          </div>
        </div>
        <div class="row mt-3" v-if="!loading">
          <div class="col-md-12 text-center" v-if="downloadLink.length>0">
            <ProgressSpinner v-if="loading" />
            <a :href="downloadLink" v-else class="btn btn-success btn-md" target="_blank" ><i class="pi pi-download "></i> Download</a>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import DashboardServices from "@/services/dashboard.services";
import ProgressSpinner from 'primevue/progressspinner';
export default {
  name: "PageModalExport",
  props:{
    data: undefined,
    showform: Boolean
  },
  components:{Dialog,ProgressSpinner},
  data:()=>({
    loading: false,
    showmodal: true,
    showaddriwayat: false,
    dataForm:{
      startdate:'',
      enddate:''
    },
    dataReport:"",
    downloadLink:""
  }),
  methods:{
    toggleview(){
      this.$emit('toggle-view',true)
      this.showmodal = false;
    },
    generateReport(){
      this.loading = true;
      this.downloadLink = "";
      this.$store.commit('loading',this.loading);
      let data = {data:this.dataForm};
      let path = '/dashboard/generate-report';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataReport = this.response.data;
              this.downloadLink = this.dataReport.link;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  watch:{
    showform:{
      immediate: true,
      deep: false,
      handler(){
        this.showmodal = this.showform
      }
    },
  },
}
</script>

<style scoped>

</style>