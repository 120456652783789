<template>
  <PageAnnouncement></PageAnnouncement>
  <DashboardNavBar></DashboardNavBar>
  <PagePenanganan></PagePenanganan>
</template>

<script>
import PageAnnouncement from "@/components/part/PageAnnouncement";
import DashboardNavBar from "@/components/part/DashboardNavBar";
import PagePenanganan from "@/components/pages/datapenanganan/PagePenanganan";
export default {
  name: "RoutePenanganan",
  components: {PagePenanganan, DashboardNavBar, PageAnnouncement}
}
</script>

<style scoped>

</style>