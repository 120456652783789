<template>
  <PageAnnouncement></PageAnnouncement>
  <DashboardNavBar></DashboardNavBar>
  <PageDashboard></PageDashboard>
</template>

<script>
import PageAnnouncement from "@/components/part/PageAnnouncement";
import PageDashboard from "@/components/pages/PageDashboard";
import DashboardNavBar from "@/components/part/DashboardNavBar";
export default {
  name: "RouteDashboard",
  components: {DashboardNavBar, PageDashboard, PageAnnouncement}
}
</script>

<style scoped>

</style>