import { createWebHistory,createRouter} from "vue-router";
import RouteDashboard from "@/components/route/RouteDashboard";
import RouteLogin from "@/components/route/RouteLogin";
import RoutePengaduan from "@/components/route/RoutePengaduan";
import RoutePenanganan from "@/components/route/RoutePenanganan";
import RouteDataUser from "@/components/route/RouteDataUser";
import RouteLogout from "@/components/route/RouteLogout";
let routes = [
    {
        path: '/',
        name: 'Home',
        component: RouteLogin,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: RouteDashboard,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/data-aduan',
        name: 'Data Aduan',
        component: RoutePengaduan,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/data-penanganan',
        name: 'Data Penanganan',
        component: RoutePenanganan,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/data-user',
        name: 'Data User',
        component: RouteDataUser,
        meta: {
            roleAdmin: false,
            authRequired: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: RouteLogin,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: RouteLogout,
        meta: {
            roleAdmin: false,
            authRequired: false,
        },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
    linkActiveClass: 'active text-success font-weight-bold',
    linkExactActiveClass: 'active',
})

router.beforeEach(async (to, from, next) => {
    const authRequired = to.meta.authRequired;
    let userData = localStorage.getItem('hmfpengaduan');
    let token = '';
    let loggedIn = false;
    //let role = 0;
    if(userData != null){
        if(userData){
            const objData = JSON.parse(userData);
            if(objData.token !== undefined){
                token = objData.token;
                if (token.length>0){
                    loggedIn = true;
                    //role = objData.role;
                }else{
                    loggedIn = false;
                }
            }else{
                loggedIn = false;
            }
        }else{
            loggedIn = false;
        }
    }
    if(authRequired){
        if(loggedIn){
            next();
        }else{
            next('/login');
        }
    }else{
        next();
    }
});

export default router;