<template>
  <div class="hmf-inside-banner-main-container">
    <div class="container h-100">
      <div class="row h-100 my-auto">
        <div class="col-12 my-auto">
          <div class="hmf-breadcrumb">Beranda / Dashboard</div>
          <div class="hmf-page-title">
            <h1 class="h1-hmf-title">Sistem Pengaduan</h1></div>
        </div>
      </div>
    </div>
  </div>
  <DashboardTop></DashboardTop>
  <PageFooter></PageFooter>
</template>

<script>
import PageFooter from "@/components/part/PageFooter";
import DashboardTop from "@/components/pages/DashboardSatu";
export default {
  name: "PageDashboard",
  components: {DashboardTop, PageFooter}
}
</script>

<style scoped>

</style>