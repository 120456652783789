<template>
  <PageAnnouncement></PageAnnouncement>
  <DashboardNavBar></DashboardNavBar>
  <PageUsers></PageUsers>
</template>

<script>
import PageAnnouncement from "@/components/part/PageAnnouncement";
import DashboardNavBar from "@/components/part/DashboardNavBar";
import PageUsers from "@/components/pages/datauser/PageUsers";
export default {
  name: "RouteDataUser",
  components: {PageUsers, DashboardNavBar, PageAnnouncement}
}
</script>

<style scoped>

</style>