<template>
  <Toast></Toast>
  <div class="ubah-riwayat mb-2 mt-2">
    <div class="row">
      <div class="col-12"><span
          class="header-col-aduan-hmf"><strong>Penambahan Riwayat Penanganan</strong></span>
      </div>
    </div>
    <div class="row input-hmf-aduan">
      <div class="col-md-3">Tanggal Penanganan</div>
      <div class="col-md-3"><input class="p-inputtext p-component" type="date" v-model="dataForm.tglpenanganan"></div>
      <InlineMessage severity="error" v-if="v$.dataForm.tglpenanganan.$error">Mohon isi tanggal</InlineMessage>
    </div>
    <div class="row input-hmf-aduan">
      <div class="col-md-3">Status</div>
      <div class="col-md-3">
        <select v-model="dataForm.status" class="p-inputtext p-component" id="status-penanganan" form="status-penanganan">
          <option value="sedangdalamproses">Sedang dalam proses</option>
          <option value="selesai">Selesai</option>
        </select>
        <InlineMessage severity="error" v-if="v$.dataForm.status.$error">Mohon pilih status</InlineMessage>
      </div>
    </div>
    <div class="row input-hmf-aduan">
      <div class="col-md-3">Bukti penanganan</div>
      <div class="col-md-3">
        <FileUpload :fileLimit="1" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel" @before-send="beforeUpload" name="fileupload" :url=urlupload :auto="true" accept="image/*, application/*, audio/*, text/plain" :maxFileSize="5000000" @progress="onProgress" @upload="onUpload" @select="onSelect"></FileUpload>
        <InlineMessage severity="error" v-if="v$.dataForm.namafile.$error">Silakan upload lampiran pendukung</InlineMessage>
      </div>
    </div>
    <div class="row input-hmf-aduan">
      <div class="col-md-3">Keterangan</div>
      <div class="col-md-6"><textarea class="w-100 p-inputtext p-component" v-model="dataForm.penanganan"></textarea></div>
      <InlineMessage severity="error" v-if="v$.dataForm.penanganan.$error">Mohon isi penanganan</InlineMessage>
    </div>
    <div class="row input-hmf-aduan">
      <div class="col-md-3">Ditangani oleh</div>
      <div class="col-md-3">{{getUserInfo}}</div>
    </div>
    <div class="row input-hmf-aduan">
      <div class="col-12">
        <button @click="submitPenanganan" class="btn  btn-md btn-info" type="button" id="btn-req-status"><i
            class="fas fa-caret-right"></i> Tambah Riwayat
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';
import useValidate from "@vuelidate/core";
import DashboardServices from "@/services/dashboard.services";
import {alphaNum, minLength, required} from "@vuelidate/validators/dist/index";
import Toast from 'primevue/toast';
import InlineMessage from 'primevue/inlinemessage';
export default {
  name: "PageAddRiwayat",
  components:{Toast,InlineMessage,FileUpload},
  props:{
    idpengaduan:undefined
  },
  data:()=>({
    v$: useValidate(),
    loading: false,
    response:'',
    message: '',
    success: false,
    urlupload:'',
    uploadLabel:"Pilih file",
    dataForm:{
      namafile:"",
      idpengaduan:"",
      tglpenanganan:"",
      status:"",
      penanganan:"",
      fileid:"",
    }
  }),
  computed:{
    getUserInfo(){
      let userinfo = this.$store.getters.getUserInfo;
      return userinfo.data.fullname;
    }
  },
  methods:{
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('hmfpengaduan'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','buktitangani');
      return request;
    },
    onProgress(event){
      this.uploadLabel = 'Upload '+event.progress+'%';
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.classupload='bg-success';
        this.dataForm.fileid = obj.data.fileid;
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
    },
    showSuccess(msg,content) {
      this.$toast.add({ severity: 'success', summary: msg, detail: content, life: 3000 });
    },
    showInfo(msg,content) {
      this.$toast.add({ severity: 'info', summary: msg, detail: content, life: 3000 });
    },
    showWarn(msg,content) {
      this.$toast.add({ severity: 'warn', summary: msg, detail: content, life: 3000 });
    },
    showError(msg,content) {
      this.$toast.add({ severity: 'error', summary: msg, detail: content, life: 3000 });
    },
    submitPenanganan(){
      this.dataForm.idpengaduan = this.idpengaduan;
      this.v$.dataForm.$validate();
      if(!this.v$.dataForm.$error){
        this.loading = true;
        let data = this.dataForm;
        let path = '/dashboard/crud-data-penanganan';
        DashboardServices.postData(data,path).then(
            (res)=>{
              this.response = res.data;
              this.message = res.data.message;
              if(res.data.status===200){
                this.success = true;
                this.showSuccess(res.data.message,res.data.message);
              }else {
                this.showError(res.data.message,res.data.message);
                this.success = false;
              }
              this.loading = false;
            }
        );
      }else{
        this.loading = false;
        this.message = 'Mohon isi data dengan benar.';
      }
    }
  },
  validations() {
    return {
      dataForm: {
        tglpenanganan:{required},
        namafile:{},
        status:{required,alphaNum,minLength:minLength(3)},
        penanganan:{required,minLength:minLength(3)},
      }
    }
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
  }
}
</script>

<style scoped>

</style>