<template>
  <Toast></Toast>
  <Dialog v-model:visible="showmodal" :dismissableMask="true" @update:visible="toggleview" header="Tambah User" :style="{ width: '75vw' }" modal :contentStyle="{ height: 'auto' }">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title">Tambah User</h3>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-striped ">
            <tbody>
            <tr>
              <td>Name</td>
              <td><input type="text" class="p-inputtext p-component" v-model="formData.nama"></td>
            </tr>
            <tr>
              <td>Email</td>
              <td><input type="text" class="p-inputtext p-component" v-model="formData.email"></td>
            </tr>
            <tr>
              <td>Password</td>
              <td><input type="password" class="p-inputtext p-component" v-model="formData.password"></td>
            </tr>
            <tr>
              <td>Ulangi Password</td>
              <td><input type="password" class="p-inputtext p-component" v-model="formData.repassword"></td>
            </tr>
            <tr>
              <td>Jenis user</td>
              <td>
                <Dropdown v-model="formData.tipeuser" :options="tipeList" optionLabel="tipe" placeholder="Pilih tipe pengguna" class="w-full md:w-14rem">
                </Dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <button class="btn  btn-md btn-info" type="button" id="btn-req-status"><i
            class="fas fa-caret-right"></i> Tambah User
        </button>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import Dropdown from 'primevue/dropdown';
export default {
  name: "PageAddUser",
  components:{Toast,Dialog,Dropdown},
  props:{
    showform: Boolean
  },
  emits: ['toggle-view'],
  data:()=>({
    showmodal: true,
    currentDataPenanganan:[],
    tipeList:[{"tipe":"Operator"},{"tipe":"Admin"}],
    formData:{
      nama:"",
      email:"",
      password:"",
      repassword:"",
      tipeuser:""
    }
  }),
  methods:{
    toggleview(){
      this.$emit('toggle-view',true)
      this.showmodal = false;
    },
    viewpagemodal(data){
      this.currentDataPenanganan = data;
      this.showmodalform = true;
    },
  },
  watch:{
    showform:{
      immediate: true,
      deep: false,
      handler(){
        this.showmodal = this.showform
      }
    },
  },
}
</script>

<style scoped>

</style>