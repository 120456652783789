<template>
  <PageAnnouncement></PageAnnouncement>
  <NavBar></NavBar>
  <PageLogin></PageLogin>
</template>

<script>
import PageLogin from "@/components/pages/PageLogin";
export default {
  name: "RouteLogout",
  components: {PageLogin},
  beforeMount() {
    this.$store.commit('setLoggedIn',false);
    this.$store.commit('deletesession');
  }
}
</script>

<style scoped>

</style>