<template>
  <div class="hmf-announcement ">
    <div class="container container-announcement my-auto">
      <div class="row h-100">
        <div class="col-12 my-auto text-center"><i class="fas fa-bullhorn" style="padding-right: 10px;"></i>COVID-19
          Update: <a href="#" class="hmf-announcement-link">Restrukturisasi Pinjaman</a> dan <a href="#"
                                                                                                class="hmf-announcement-link">Jam
            Operasi</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageAnnouncement"
}
</script>

<style scoped>

</style>