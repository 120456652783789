<template>
  <!--PENANGANAN-->
  <div class="section-title" v-if="useheader">
    <h2 class="h2-hmf-section">Data penanganan paling akhir</h2>
  </div>
  <div class="row header-section-pengaduan" v-if="usebutton">
    <div class="col-md-6">
      <div class="section-title">
        <h2 class="h2-hmf-section">Data penanganan </h2>
      </div>
    </div>
    <div class="col-md-6 text-right"></div>
  </div>
  <Transition name="bounce">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <DataTable v-model:filters="filters"
                     :value="dataPenanganan"
                     ref="dt"
                     tableStyle="min-width: 50rem"
                     :paginator="true"
                     :rows="5"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate="Tampil {first} dari {last} of {totalRecords} data pengaduan"
                     :first="0"
                     :loading="loading"
                     class="p-datatable-sm"
                     sortMode="single"
                     rowGroupMode="subheader" groupRowsBy="no_pengaduan"
                     scrollable
                     sortField="no_pengaduan"
                     stripedRows>
            <template #header v-if="!useheader">
              <div class="flex justify-content-end">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Pencarian" />
                </span>
              </div>
            </template>
            <template #empty> Data tidak ada. </template>
            <template #loading> Mengambil data. Mohon tunggu. </template>
            <Column header="No Aduan" sortable style="min-width:16rem">
              <template #body="slotProps">
                <a href="#" @click="viewpagemodal(slotProps.data)">{{slotProps.data.no_pengaduan}}</a>
              </template>
            </Column>
            <Column field="tgl_pengaduan" header="Tanggal penanganan" sortable style="min-width:16rem"></Column>
            <Column field="nasabah_nama" header="Nama Nasabah" sortable style="min-width:16rem"></Column>
            <Column field="no_kontrak" header="Nomor Kontrak" sortable style="min-width:16rem"></Column>
            <Column field="status_aduan" header="Status" sortable style="min-width:16rem"></Column>
            <Column header="Detil" sortable style="min-width:16rem">
              <template #body="slotProps">
                <button @click="viewpagemodal(slotProps.data)" type="button" class="btn btn-info btn-sm" data-toggle="modal"
                        data-target="#modalpenanganan">
                  Lihat
                </button>
              </template>
            </Column>
            <template #groupheader="slotProps">
              <div class="flex align-items-center gap-2">
                <span class="font-weight-bold">Nomor Aduan : {{ slotProps.data.no_pengaduan }}</span>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </Transition>
  <transition name="bounce" mode="out-in">
  </transition>
  <PageModalPenanganan :data="currentDataPenanganan" :showform="showmodalform" @toggle-view="togglemodal"></PageModalPenanganan>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {FilterMatchMode} from "primevue/api";
import DashboardServices from "@/services/dashboard.services";
import PageModalPenanganan from "@/components/pages/part/PageModalPenanganan";
import InputText from 'primevue/inputtext';
import 'primeicons/primeicons.css'
export default {
  name: "PageDataPenanganan",
  components: {PageModalPenanganan, DataTable,Column,InputText},
  props:{
    useheader:Boolean,
    usebutton: Boolean
  },
  data:()=>({
    viewform: false,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    classnya: 'fa fa-plus',
    classbutton:'btn btn-success btn-md',
    response:[],
    message:'',
    loading: false,
    success:false,
    dataPenanganan:[],
    currentDataPenanganan:[],
    showmodalform: false
  }),
  methods:{
    togglemodal(){
      this.showmodalform = false;
    },
    viewpagemodal(data){
      this.currentDataPenanganan = data;
      this.showmodalform = true;
    },
    toggleForm(){
      this.viewform = !this.viewform;
      if(!this.viewform){
        this.classnya = 'fa fa-plus';
        this.classbutton = 'btn btn-success btn-md';
      }else{
        this.classnya = 'fa fa-minus';
        this.classbutton = 'btn btn-warning btn-md';
      }
    },
    getDataPenanganan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10};
      let path = '/dashboard/load-data-penanganan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPenanganan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataPenanganan();
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  transform: translateX(20px);
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>