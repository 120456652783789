<template>
  <ProgressSpinner v-if="loading" />
  <div class="table-responsive" v-else>
    <table class="table table-striped ">
      <thead>
      <tr>
        <th scope="col"><span class="header-col-aduan-hmf">Riwayat Penanganan</span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><strong>Tanggal</strong></td>
        <td><strong>Status</strong></td>
        <td><strong>Keterangan</strong></td>
        <td><strong>Ditangani oleh</strong></td>
      </tr>
      <tr v-for="(item,idx) in dataRiwayatPenanganan" :key="idx">
        <td>{{item.tgl_penanganan}}</td>
        <td>{{item.status_pnganan}}</td>
        <td>{{item.penanganan}}</td>
        <td>{{item.full_name}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import ProgressSpinner from 'primevue/progressspinner';
export default {
  name: "PageListRiwayat",
  components:{ProgressSpinner},
  props:{
    datapengaduan:undefined
  },
  data:()=>({
    loading: "",
    showaddriwayat: false,
    dataRiwayatPenanganan:[],
  }),
  methods:{
    getDataPenanganan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10,idpengaduan:this.datapengaduan.id_pengaduan};
      let path = '/dashboard/load-data-penanganan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataRiwayatPenanganan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataPenanganan();
  }
}
</script>

<style scoped>

</style>