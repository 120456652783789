<template>
  <BreadCrumb></BreadCrumb>
  <Toast />
  <div class="container hmf-main-content-area">
    <div class="section-title">
      <h2 class="h2-hmf-section">Login</h2>
    </div>
    <div class="row">
      <div class="col-md-3">
        <input v-model="accountData.email" type="text" class="w-100 mb-1">
        <InlineMessage severity="error" v-if="v$.accountData.email.$error">Mohon isi nama pengguna (Email)</InlineMessage>
        <label v-else>Username</label>
      </div>
      <div class="col-md-3">
        <input v-model="accountData.password" type="password" class="w-100 mb-1">
        <InlineMessage severity="error" v-if="v$.accountData.password.$error">Mohon isi password</InlineMessage>
        <label v-else>Password</label>
      </div>
      <div class="col-md-3">
        <button @click="submitLogin" class="btn btn-danger" type="button" id="btn-req-status"><i class="fas fa-caret-right"></i> Login
        </button>
      </div>
    </div>
  </div>
  <PageFooter></PageFooter>
</template>

<script>
import "primevue/resources/themes/lara-light-indigo/theme.css";
import useValidate from "@vuelidate/core";
import {alphaNum, email, minLength, required} from "@vuelidate/validators";
import PageFooter from "@/components/part/PageFooter";
import BreadCrumb from "@/components/part/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import router from "@/router";
import Toast from 'primevue/toast';
import InlineMessage from 'primevue/inlinemessage';
export default {
  name: "PageLogin",
  components: {BreadCrumb, PageFooter,Toast,InlineMessage},
  data:()=>({
    v$: useValidate(),
    loading: false,
    response:'',
    message: '',
    success: false,
    accountData:{
      email:'',
      password:''
    }
  }),
  methods:{
    showSuccess(msg,content) {
      this.$toast.add({ severity: 'success', summary: msg, detail: content, life: 3000 });
    },
    showInfo(msg,content) {
      this.$toast.add({ severity: 'info', summary: msg, detail: content, life: 3000 });
    },
    showWarn(msg,content) {
      this.$toast.add({ severity: 'warn', summary: msg, detail: content, life: 3000 });
    },
    showError(msg,content) {
      this.$toast.add({ severity: 'error', summary: msg, detail: content, life: 3000 });
    },
    doClearForm(){
      this.accountData.email = '';
      this.accountData.password = '';
    },
    goHome(){
      //router.push('/home');
    },
    submitLogin(){
      this.v$.accountData.$validate();
      if(!this.v$.accountData.$error){
        this.loading = true;
        this.$store.commit('loading',this.loading);
        let data = this.accountData;
        let path = '/api/login';
        DashboardServices.postData(data,path).then(
            (res)=>{
              this.response = res.data;
              this.message = res.data.message;
              if(res.data.status===200){
                this.success = true;
                this.showSuccess(res.data.message,res.data.message);
                this.$store.commit('savesession',this.response);
                router.push('/dashboard');
              }else {
                this.showError(res.data.message,res.data.message);
                this.success = false;
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.loading = false;
        this.$store.commit('loading',this.loading);
        this.message = 'Mohon isi data dengan benar.';
      }
    }
  },
  validations() {
    return {
      accountData: {
        email:{required,email},
        password:{required,alphaNum,minLength:minLength(8)},
      }
    }
  },
}
</script>

<style scoped>

</style>