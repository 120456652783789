<template>
  <button type="button" class="btn btn-info btn-sm mt-2 mb-2" @click="toggleaddriwayat">Tambah Riwayat</button>
  <PageAddRiwayat v-if="showaddriwayat" :idpengaduan="idpengaduan"></PageAddRiwayat>
  <div class="table-responsive">
    <table class="table table-striped ">
      <thead>
      <tr>
        <th scope="col"><span class="header-col-aduan-hmf">Riwayat Penanganan</span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><strong>Tanggal</strong></td>
        <td><strong>Status</strong></td>
        <td><strong>Keterangan</strong></td>
        <td><strong>Ditangani oleh</strong></td>
      </tr>
      <tr v-for="(item,idx) in data" :key="idx">
        {{setidpengaduan(item.id_pengaduan)}}
        <td>{{item.tgl_penanganan}}</td>
        <td>{{item.status_pnganan}}</td>
        <td>{{item.penanganan}}</td>
        <td>{{item.full_name}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import PageAddRiwayat from "@/components/pages/datapenanganan/PageAddRiwayat";
export default {
  name: "PageRiwayatPenanganan",
  components: {PageAddRiwayat},
  props:{
    data: undefined
  },
  data:()=>({
    idpengaduan: "",
    showaddriwayat: false,
  }),
  computed:{
    getUserInfo(){
      let userinfo = this.$store.getters.getUserInfo;
      return userinfo.data.fullname;
    }
  },
  methods:{
    setidpengaduan(id){
      this.idpengaduan = id;
    },
    toggleaddriwayat(){
      this.showaddriwayat = !this.showaddriwayat;
    },
  }

}
</script>

<style scoped>

</style>