<template>
  <div class="hmf-inside-banner-main-container">
    <div class="container h-100">
      <div class="row h-100 my-auto">
        <div class="col-12 my-auto">
          <div class="hmf-breadcrumb">Beranda / Login</div>
          <div class="hmf-page-title">
            <h1 class="h1-hmf-title">Sistem Pengaduan</h1></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb"
}
</script>

<style scoped>

</style>