<template>
  <div class="container hmf-main-content-area">
    <hr>
    <div class="section-title">
      <h2 class="h2-hmf-section">Statistik Pengaduan</h2>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-3">Jumlah aduan aktif :<br><span class="status-statistik">{{datadashboard.jumlahaduan}}</span></div>
      <div class="col-md-3">Dalam proses :<br><span class="status-statistik">{{datadashboard.dalamproses}}</span></div>
      <div class="col-md-3">Belum ditangani :<br><span class="status-statistik">{{datadashboard.belumditangani}}</span></div>
      <div class="col-md-3">Aduan terselesaikan :<br><span class="status-statistik">{{datadashboard.terselesaikan}}</span></div>
    </div>
    <hr>
    <PageDataPengaduan
        :useheader=true
        :usebutton=false
    ></PageDataPengaduan>
    <PageDataPenanganan
        :useheader=true
        :usebutton=false
    ></PageDataPenanganan>
  </div>
</template>

<script>
import PageDataPengaduan from "@/components/pages/part/PageDataPengaduan";
import PageDataPenanganan from "@/components/pages/part/PageDataPenanganan";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "DashboardTop",
  components: {PageDataPenanganan, PageDataPengaduan},
  data:()=>({
    showmodal: true,
    datadashboard:"",
  }),
  methods:{
    showclass(){
    },
    getDashboard(){
      let data = {};
      let path = '/dashboard/data-dashboard';
      DashboardServices.postData(data,path).then(
          (res)=>{
            this.response = res.data;
            this.message = res.data.message;
            if(res.data.status===200){
              this.datadashboard = res.data.data;
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
          }
      );
    }
  },
  mounted() {
    this.getDashboard();
  }
}
</script>

<style scoped>

</style>