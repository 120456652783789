<template>
  <PageAnnouncement></PageAnnouncement>
  <NavBar></NavBar>
  <PageLogin></PageLogin>
</template>

<script>
import PageLogin from "@/components/pages/PageLogin";
export default {
  name: "RouteLogin",
  components: {PageLogin}
}
</script>

<style scoped>

</style>