<template>
  <div class="hmf-inside-banner-main-container">
    <div class="container h-100">
      <div class="row h-100 my-auto">
        <div class="col-12 my-auto">
          <div class="hmf-breadcrumb">Beranda / Data User</div>
          <div class="hmf-page-title">
            <h1 class="h1-hmf-title">Data User Sistem Pengaduan Nasabah</h1></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container hmf-main-content-area">
    <PageListDataUser></PageListDataUser>
  </div>
  <PageFooter></PageFooter>
</template>

<script>
import PageFooter from "@/components/part/PageFooter";
import PageListDataUser from "@/components/pages/dataaduan/PageListDataUser";
export default {
  name: "PageUsers",
  components: {PageListDataUser, PageFooter}
}
</script>

<style scoped>

</style>