<template>
  <PageAnnouncement></PageAnnouncement>
  <DashboardNavBar></DashboardNavBar>
  <PageAduan></PageAduan>
</template>

<script>
import PageAnnouncement from "@/components/part/PageAnnouncement";
import DashboardNavBar from "@/components/part/DashboardNavBar";
import PageAduan from "@/components/pages/dataaduan/PageAduan";
export default {
  name: "RoutePengaduan",
  components: {PageAduan, DashboardNavBar, PageAnnouncement}
}
</script>

<style scoped>

</style>